<template>
  <section class="w-full custom-class">
    <div v-if="cargando" style="width:100%; height: 300px" class="rounded-md icono-carga flex justify-center items-center relative">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
    </div>
    <div class="m-4" v-else>
      <Button
        label="Buscar"
        icon="pi pi-search"
        class="p-button-xs mb-4"
        @click="handleSearch"
      />
      <Button
        label="Limpiar"
        icon="pi pi-trash"
        class="p-button-xs mb-4 p-button-danger"
        @click="handleClearFilters"
      />
      <div v-if="costos[0]" class="grid grid-cols-12 gap-2 pb-4">

        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-8" class="accordion-header flex justify-end col-span-2">
            <button
              class="accordion-button collapsed text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-8"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-8"
              @click="showHideIngCosCliente = !showHideIngCosCliente"
            >
              <span class="text-right font-bold col-span-6 text-sm">Inf. x Cliente <small>{{ showHideIngCosCliente ? '...Ver menos' : 'Ver más...' }}</small></span>
            </button>
          </div>
          <div id="faq-accordion-collapse-8" class="accordion-collapse collapse hidden" aria-labelledby="faq-accordion-content-8" data-bs-parent="#faq-accordion-8">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getIngresosCostosCliente"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                >
                  <ColumnGroup type="header">
                    <Row>
                      <Column header="Cliente" style="font-weight: bold" field="cliente" headerStyle="width:20%"/>
                      <Column header="Ingreso" style="font-weight: bold" field="ingreso"/>
                      <Column header="Costo" style="font-weight: bold" field="costo"/>
                      <Column header="% Utilidad" style="font-weight: bold" field="percent"/>
                      <Column header="% Rentabilidad" style="font-weight: bold" field="percentRentabilidad"/>
                      <Column header="% Ingreso" style="font-weight: bold" field="percentPesoIngreso"/>
                      <Column header="% Costo" style="font-weight: bold" field="percentPesoCosto"/>
                    </Row>
                    <Row>
                      <Column header="Totales:" :colspan="1" footerStyle="text-align:right"/>
                      <Column :header="totalIngresoCliente" />
                      <Column :header="totalCostoCliente" />
                      <Column header="" />
                      <Column header="" />
                      <Column :header="totalpercentPesoIngresoCliente" />
                      <Column :header="totalpercentPesoCostoCliente" />
                    </Row>
                  </ColumnGroup>
                  <Column header="Cliente" field="cliente"></Column>
                  <Column header="Ingreso" field="ingreso">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.ingreso, 0) }}
                    </template>
                  </Column>
                  <Column header="Costo" field="costo">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.costo, 0) }}
                    </template>
                  </Column>
                  <Column header="%" field="percent">
                    <template #body="{ data }">
                      {{ data.percent ? data.percent.toFixed(0) : '0'}}%
                    </template>
                  </Column>
                  <Column header="% Rentabilidad" field="percentRentabilidad">
                    <template #body="{ data }">
                      {{ data.percentRentabilidad.toFixed(0) }}%
                    </template>
                  </Column>
                  <Column header="% Ingreso" field="percentPesoIngreso">
                    <template #body="{ data }">
                      {{ data.percentPesoIngreso.toFixed(1) }}%
                    </template>
                  </Column>
                  <Column header="% Costo" field="percentPesoCosto">
                    <template #body="{ data }">
                      {{ data.percentPesoCosto.toFixed(1) }}%
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-1" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-6 text-sm">Ingreso</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">

              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getIngresos"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :rowClass="() => 'rowColorIngresos'"
                >
                  <Column header="" field="total" style="font-weight: bold"></Column>
                  <Column header="Vlr Anual" field="valueGeneral">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueGeneral, 0) }}
                    </template>
                  </Column>
                  <Column header="% Anual" field="percentAnual">
                    <template #body="{ data }">
                      {{ data.percentAnual }}
                    </template>
                  </Column>
                  <Column header="Vlr Filtro" field="valueFiltro">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueFiltro, 0) }}
                    </template>
                  </Column>
                  <Column header="% Filtro" field="percentFiltro">
                    <template #body="{ data }">
                      {{ data.percentFiltro }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-2" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-6 text-sm">Costo</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-2" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getCostos"
                  v-model:expandedRows="expandedRowsCostos"
                  @rowExpand="onRowExpandCostos"
                  @rowCollapse="onRowCollapseCostos"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :rowClass="() => 'rowColorCostos'"
                >
                  <Column :expander="true" headerStyle="width: 3rem"/>
                  <Column header="" field="total" style="font-weight: bold"></Column>
                  <Column header="Vlr Anual" field="valueGeneral">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueGeneral, 0) }}
                    </template>
                  </Column>
                  <Column header="% Anual" field="percentAnual">
                    <template #body="{ data }">
                      {{ data.percentAnual }}
                    </template>
                  </Column>
                  <Column header="Vlr Filtro" field="valueFiltro">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueFiltro, 0) }}
                    </template>
                  </Column>
                  <Column header="% Filtro" field="percentFiltro">
                    <template #body="{ data }">
                      {{ data.percentFiltro }}
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="">
                      <DataTable
                        :value="slotProps.data.costoDetails"
                        showGridlines
                        responsiveLayout="scroll"
                        class="p-datatable-sm p-2 text-sm"
                      >
                        <Column header="" field="total">
                          <template #body="{ data }">
                            {{ data.total }}
                          </template>
                        </Column>
                        <Column header="Vlr Anual" field="valueGeneral">
                          <template #body="{ data }">
                            {{ $h.formatCurrency(data.valueGeneral, 0) }}
                          </template>
                        </Column>
                        <Column header="Vlr Filtro" field="valueFiltro">
                          <template #body="{ data }">
                            {{ $h.formatCurrency(data.valueFiltro, 0) }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-9" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-9"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-9"
            >
              <span class="text-left font-bold col-span-6 text-sm">Utilidad Bruta</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-9" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-9" data-bs-parent="#faq-accordion-9">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">

              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getUtilidadBruta"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :rowClass="() => 'rowColorUtilBruta'"
                >
                  <Column header="" field="total" style="font-weight: bold"></Column>
                  <Column header="Vlr Anual" field="valueGeneral">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueGeneral, 0) }}
                    </template>
                  </Column>
                  <Column header="% Anual" field="percentAnual">
                    <template #body="{ data }">
                      {{ data.percentAnual }}
                    </template>
                  </Column>
                  <Column header="Vlr Filtro" field="valueFiltro">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueFiltro, 0) }}
                    </template>
                  </Column>
                  <Column header="% Filtro" field="percentFiltro">
                    <template #body="{ data }">
                      {{ data.percentFiltro }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-3" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-4 text-sm">Gasto</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-3" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getGastos"
                  v-model:expandedRows="expandedRowsGastos"
                  @rowExpand="onRowExpandGastos"
                  @rowCollapse="onRowCollapseGastos"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :rowClass="() => 'rowColorGastos'"
                >
                  <Column :expander="true" headerStyle="width: 3rem"/>
                  <Column header="" field="total" style="font-weight: bold"></Column>
                  <Column header="Vlr Anual" field="valueGeneral">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueGeneral, 0) }}
                    </template>
                  </Column>
                  <Column header="% Anual" field="percentAnual">
                    <template #body="{ data }">
                      {{ data.percentAnual }}
                    </template>
                  </Column>
                  <Column header="Vlr Filtro" field="valueFiltro">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.valueFiltro, 0) }}
                    </template>
                  </Column>
                  <Column header="% Filtro" field="percentFiltro">
                    <template #body="{ data }">
                      {{ data.percentFiltro }}
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="">
                      <DataTable
                        :value="slotProps.data.gastoDetails"
                        showGridlines
                        responsiveLayout="scroll"
                        class="p-datatable-sm p-2 text-sm"
                      >
                        <Column header="" field="total">
                          <template #body="{ data }">
                            {{ data.total }}
                          </template>
                        </Column>
                        <Column header="Vlr Anual" field="valueGeneral">
                          <template #body="{ data }">
                            {{ $h.formatCurrency(data.valueGeneral, 0) }}
                          </template>
                        </Column>
                        <Column header="Vlr Filtro" field="valueFiltro">
                          <template #body="{ data }">
                            {{ $h.formatCurrency(data.valueFiltro, 0) }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <!-- Tabla final -->
        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-7" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-4 text-sm">Utilidad Operacional</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-7" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getItemsUtilidad"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                >
                  <ColumnGroup type="header">
                    <Row>
                      <Column header="Concepto" style="font-weight: bold" field="concepto" headerStyle="width:20%"/>
                      <Column header="Vlr Anual" style="font-weight: bold" field="totalGeneral"/>
                      <Column header="% Anual" style="font-weight: bold" field="percentGeneral"/>
                      <Column header="Vlr Filtro" style="font-weight: bold" field="totalFiltrado"/>
                      <Column header="% Filtro" style="font-weight: bold" field="percentFiltrado"/>
                    </Row>
                  </ColumnGroup>
                  <Column field="concepto">
                    <template #body="{ data }">
                      {{ data.concepto }}
                    </template>
                  </Column>
                  <Column field="totalGeneral">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.totalGeneral) }}
                    </template>
                  </Column>
                  <Column field="percentGeneral">
                    <template #body="{ data }">
                      {{ data.percentGeneral }}
                    </template>
                  </Column>
                  <Column field="totalFiltrado">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.totalFiltrado) }}
                    </template>
                  </Column>
                  <Column field="percentFiltrado">
                    <template #body="{ data }">
                      {{ data.percentFiltrado }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider align="center" type="dashed" class="col-span-12 mt-0 details">
          <b>Detalles</b>
        </Divider>

        <!-- Table detalle gastos -->
        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-4" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-4 text-sm">Detalle Gastos</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-4" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getItemsGastos"
                  :paginator="true"
                  :rows="10"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  :rowsPerPageOptions="[5,10,25]"
                  currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
                  responsiveLayout="scroll"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :globalFilterFields="['cmayor']"
                  v-model:filters="filtersTableGastos"
                >
                  <template #header>
                    <div class="p-d-flex p-jc-end p-ai-center">
                      <span class="p-input-icon-right">
                        <i class="pi pi-search" />
                        <InputText
                          v-model="filtersTableGastos['global'].value"
                          placeholder="Buscar..."
                          class="p-inputtext-xs"
                        />
                      </span>
                    </div>
                  </template>
                  <ColumnGroup type="header">
                    <Row>
                      <Column header="" style="font-weight: bold" field="cmayor" headerStyle="width:20%"/>
                      <Column header="Gasto Anual" style="font-weight: bold" field="GastoGeneral"/>
                      <Column header="Gasto (Filtros)" style="font-weight: bold" field="GastoFiltrado"/>
                      <Column header="%Gasto" style="font-weight: bold" field="percent"/>
                      <Column header="Gasto Asignado" style="font-weight: bold" field="gastoAsig"/>
                      <Column header="Gasto Distribuido" style="font-weight: bold" field="gastoDist"/>
                    </Row>
                    <Row>
                      <Column header="Totales:" :colspan="1" footerStyle="text-align:right"/>
                      <Column :header="totalGastoGeneral" />
                      <Column :header="totalGasto" />
                      <Column :header="totalGastoPercent" />
                      <Column :header="totalGastoAsignado" />
                      <Column :header="totalGastoDistribuido" />
                    </Row>
                  </ColumnGroup>
                  <Column field="cmayor">
                    <template #body="{ data }">
                      {{ data.cmayor ? data.cmayor : 'SEGURO DE VIDA'}}
                    </template>
                  </Column>
                  <Column field="GastoGeneral">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.GastoGeneral, 0) }}
                    </template>
                  </Column>
                  <Column field="GastoFiltrado">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.GastoFiltrado, 0) }}
                    </template>
                  </Column>
                  <Column field="percent">
                    <template #body="{ data }">
                      {{ data.percent.toFixed(0) }}%
                    </template>
                  </Column>
                  <Column field="gastoAsig">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.gastoAsig, 0) }}
                    </template>
                  </Column>
                  <Column field="gastoDist">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.gastoDist, 0) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <!-- Table zonificacion -->
        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-5" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-4 text-sm">Zonificación</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-5" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getItemsZonificacion"
                  :paginator="true"
                  :rows="10"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  :rowsPerPageOptions="[5,10,25]"
                  currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
                  responsiveLayout="scroll"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :globalFilterFields="['municuser']"
                  v-model:filters="filtersTableZonificacion"
                >
                  <template #header>
                    <div class="p-d-flex p-jc-end p-ai-center">
                      <span class="p-input-icon-right">
                        <i class="pi pi-search" />
                        <InputText
                          v-model="filtersTableZonificacion['global'].value"
                          placeholder="Buscar..."
                          class="p-inputtext-xs"
                        />
                      </span>
                    </div>
                  </template>
                  <ColumnGroup type="header">
                    <Row>
                      <Column header="Zonificación" style="font-weight: bold" field="municuser" headerStyle="width:20%"/>
                      <Column header="Ingresos" style="font-weight: bold" field="ingreso"/>
                      <Column header="Costos" style="font-weight: bold" field="costo"/>
                      <Column header="Gastos" style="font-weight: bold" field="gastoTotal"/>
                      <Column header="Util Operacional" style="font-weight: bold" field="utilOperacional"/>
                      <Column header="%Utilidad" style="font-weight: bold" field="percentUtilOperacional"/>
                      <Column header="%Rentabilidad" style="font-weight: bold" field="percentMargen"/>
                    </Row>
                    <Row>
                      <Column header="Totales:" :colspan="1" footerStyle="text-align:right"/>
                      <Column :header="totalIngresosZonificacion" />
                      <Column :header="totalCostosZonificacion" />
                      <Column :header="totalGastosZonificacion" />
                      <Column header="" />
                      <Column header="" />
                      <Column header="" />
                    </Row>
                  </ColumnGroup>
                  <Column field="municuser">
                    <template #body="{ data }">
                      {{ data.municuser ? data.municuser : 'SIN ZONIFICACIÓN'}}
                    </template>
                  </Column>
                  <Column field="ingreso">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.ingreso, 0) }}
                    </template>
                  </Column>
                  <Column field="costo">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.costo, 0) }}
                    </template>
                  </Column>
                  <Column field="gastoTotal">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.gastoTotal, 0) }}
                    </template>
                  </Column>
                  <Column field="utilOperacional">
                    <template #body="{ data }">
                      {{ data.utilOperacional }}
                    </template>
                  </Column>
                  <Column field="percentUtilOperacional">
                    <template #body="{ data }">
                      {{ data.percentUtilOperacional }}
                    </template>
                  </Column>
                  <Column field="percentMargen">
                    <template #body="{ data }">
                      {{ data.percentMargen }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <!-- Table bodega -->
        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-6" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-4 text-sm">Bodega</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-6" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getItemsBodega"
                  :paginator="true"
                  :rows="10"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  :rowsPerPageOptions="[5,10,25]"
                  currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
                  responsiveLayout="scroll"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :globalFilterFields="['bodega']"
                  v-model:filters="filtersTableBodega"
                >
                  <template #header>
                    <div class="p-d-flex p-jc-end p-ai-center">
                      <span class="p-input-icon-right">
                        <i class="pi pi-search" />
                        <InputText
                          v-model="filtersTableBodega['global'].value"
                          placeholder="Buscar..."
                          class="p-inputtext-xs"
                        />
                      </span>
                    </div>
                  </template>
                  <ColumnGroup type="header">
                    <Row>
                      <Column header="Bodega" style="font-weight: bold" field="bodega" headerStyle="width:20%"/>
                      <Column header="Ingresos" style="font-weight: bold" field="ingreso"/>
                      <Column header="Costos" style="font-weight: bold" field="costo"/>
                      <Column header="Gastos" style="font-weight: bold" field="gastoTotal"/>
                      <Column header="Util Operacional" style="font-weight: bold" field="utilOperacional"/>
                      <Column header="%Utilidad" style="font-weight: bold" field="percentUtilOperacional"/>
                      <Column header="%Rentabilidad" style="font-weight: bold" field="percentMargen"/>
                    </Row>
                    <Row>
                      <Column header="Totales:" :colspan="1" footerStyle="text-align:right"/>
                      <Column :header="totalIngresosBodega" />
                      <Column :header="totalCostosBodega" />
                      <Column :header="totalGastosBodega" />
                      <Column header="" />
                      <Column header="" />
                      <Column header="" />
                    </Row>
                  </ColumnGroup>
                  <Column field="municuser">
                    <template #body="{ data }">
                      {{ data.bodega ? data.bodega : 'SIN BODEGA'}}
                    </template>
                  </Column>
                  <Column field="ingreso">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.ingreso, 0) }}
                    </template>
                  </Column>
                  <Column field="costo">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.costo, 0) }}
                    </template>
                  </Column>
                  <Column field="gastoTotal">
                    <template #body="{ data }">
                      {{ $h.formatCurrency(data.gastoTotal, 0) }}
                    </template>
                  </Column>
                  <Column field="utilOperacional">
                    <template #body="{ data }">
                      {{ data.utilOperacional }}
                    </template>
                  </Column>
                  <Column field="percentUtilOperacional">
                    <template #body="{ data }">
                      {{ data.percentUtilOperacional }}
                    </template>
                  </Column>
                  <Column field="percentMargen">
                    <template #body="{ data }">
                      {{ data.percentMargen }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref } from 'vue'
import { apiListCost, apiListCostBodega, apiListCostGastos, apiListCostZonificacion } from '../../service'
import { useStore } from 'vuex'
import { helper } from '../../../../../../../utils/helper'
import currency from 'currency.js'
import { FilterMatchMode } from 'primevue/api'
import { messageWarning } from '../../../../../../../libs/mensajes'

export default {
  name: 'ListaCostos',
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const store = useStore()
    const cargando = ref(false)
    const costos = ref([])
    const costosZonificacion = ref([])
    const getItemsGastos = ref([])
    const getItemsGastosGeneral = ref([])
    const costosBodega = ref([])
    const searchNivelZonificacion = ref('')
    const searchNivelBodega = ref('')
    const getIngresos = ref([])
    const getCostos = ref([])
    const getUtilidadBruta = ref([])
    const getGastos = ref([])
    const getIngresosCostosCliente = ref([])
    const getTotalesIngresosCostosCliente = ref([])
    const showHideIngCosCliente = ref(false)
    const getItemsUtilidad = ref([])
    const expandedRowsGastos = ref([])
    const expandedRowsCostos = ref([])
    const gastoDetails = ref([])
    const costoDetails = ref([])

    const getterFilters = computed(() => {
      return store.getters['filtersCardCostos/getFilters']
    })

    const loadData = () => {
      cargando.value = true
      return apiListCost(getterFilters.value).then(({ status, data: response }) => {
        console.log('Response: ', response)
        const claves = Object.keys(response.dataGeneral[0])
        costos.value = claves.map((item) => {
          return {
            [`${item}`]: response.data[0][item],
            [`${item}General`]: response.dataGeneral[0][item]
          }
        })
        console.log('Costos: ', costos.value)
        getIngresos.value = [
          {
            total: 'Total',
            valueGeneral: costos.value[0].ingresoGeneral,
            percentAnual: '100%',
            valueFiltro: costos.value[0].ingreso,
            percentFiltro: ((costos.value[0].ingreso / costos.value[0].ingresoGeneral) * 100).toFixed(0) + '%'
          }
        ]
        getCostos.value = [
          {
            total: 'Costo Total',
            valueGeneral: costos.value[1].costoGeneral + costos.value[5].intermediacionGeneral,
            percentAnual: ((costos.value[1].costoGeneral / costos.value[0].ingresoGeneral) * 100).toFixed(0) + '%',
            valueFiltro: costos.value[1].costo + costos.value[5].intermediacion,
            percentFiltro: ((costos.value[1].costo / costos.value[1].costoGeneral) * 100).toFixed(0) + '%',
            costoDetails: [
              {
                total: ' → Costo Mx',
                valueGeneral: costos.value[1].costoGeneral,
                percentAnual: ((costos.value[1].costoGeneral / costos.value[0].ingresoGeneral) * 100).toFixed(0) + '%',
                valueFiltro: costos.value[1].costo,
                percentFiltro: ((costos.value[1].costo / costos.value[1].costoGeneral) * 100).toFixed(0) + '%'
              },
              {
                total: ' → Intermediación',
                valueGeneral: costos.value[5].intermediacionGeneral,
                percentAnual: '',
                valueFiltro: costos.value[5].intermediacion,
                percentFiltro: ''
              }
            ]
          }
        ]
        getUtilidadBruta.value = [
          {
            total: 'Total',
            valueGeneral: costos.value[0].ingresoGeneral - costos.value[1].costoGeneral,
            percentAnual: 100 - ((costos.value[1].costoGeneral / costos.value[0].ingresoGeneral) * 100).toFixed(0) + '%',
            valueFiltro: costos.value[0].ingreso - costos.value[1].costo,
            percentFiltro: ((costos.value[0].ingreso / costos.value[0].ingresoGeneral) * 100).toFixed(0) - ((costos.value[1].costo / costos.value[1].costoGeneral) * 100).toFixed(0) + '%'
          }
        ]
        getGastos.value = [
          {
            total: 'Gasto Total',
            valueGeneral: costos.value[4].gastoTotalGeneral,
            percentAnual: ((costos.value[4].gastoTotalGeneral / costos.value[0].ingresoGeneral) * 100).toFixed(0) + '%',
            valueFiltro: costos.value[4].gastoTotal,
            percentFiltro: ((costos.value[4].gastoTotal / costos.value[0].ingreso) * 100).toFixed(0) + '%',
            gastoDetails: [
              {
                total: ' → Gasto Asignado',
                valueGeneral: costos.value[2].gastoAsigGeneral,
                percentAnual: '',
                valueFiltro: costos.value[2].gastoAsig,
                percentFiltro: ''
              },
              {
                total: ' → Gasto Distribuido',
                valueGeneral: costos.value[3].gastoDistrGeneral,
                percentAnual: '',
                valueFiltro: costos.value[3].gastoDistr,
                percentFiltro: ''
              }
            ]
          }
        ]

        getTotalesIngresosCostosCliente.value = response.dataIngresoCostoCliente.reduce(function (aggregate, item) {
          // suma de valores totales
          aggregate.totalIngreso += item.ingreso
          aggregate.totalCosto += item.costo

          return aggregate
        }, { totalIngreso: 0, totalCosto: 0 })

        getIngresosCostosCliente.value = response.dataIngresoCostoCliente.map((item) => {
          return {
            ...item,
            percentRentabilidad: ((item.ingreso - item.costo) / item.costo) * 100,
            percentPesoIngreso: (item.ingreso / getTotalesIngresosCostosCliente.value.totalIngreso) * 100,
            percentPesoCosto: (item.costo / getTotalesIngresosCostosCliente.value.totalCosto) * 100
          }
        })
      }).catch(err => {
        console.error(err.message)
      })
    }

    const loadDataGastos = () => {
      cargando.value = true
      return apiListCostGastos(getterFilters.value).then(({ status, data: response }) => {
        getItemsGastos.value = response.dataGeneral.map((item, i) => {
          for (const obj2 of response.data) {
            if (item.cmayor === obj2.cmayor) {
              obj2.GastoGeneral = item.Gasto
              obj2.GastoFiltrado = obj2.gastoAsig + obj2.gastoDist
              return obj2
            }
          }

          return {
            CMayor: item.cmayor,
            Gasto: 0,
            GastoFiltrado: 0,
            GastoGeneral: item.Gasto,
            gastoAsig: 0,
            gastoDist: 0,
            percent: 0
          }
        })

        for (const obj of getItemsGastos.value) {
          if (obj.cmayor === undefined || obj.cmayor === null) {
            obj.cmayor = ''
          }
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    const loadDataZonificacion = () => {
      cargando.value = true
      return apiListCostZonificacion(getterFilters.value).then(({ status, data: response }) => {
        costosZonificacion.value = response.data
        for (const obj of costosZonificacion.value) {
          if (obj.municuser === undefined || obj.municuser === null) {
            obj.municuser = ''
          }
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    const loadDataBodega = () => {
      cargando.value = true
      return apiListCostBodega(getterFilters.value).then(({ status, data: response }) => {
        costosBodega.value = response.data
        for (const obj of costosBodega.value) {
          if (obj.bodega === undefined || obj.bodega === null) {
            obj.bodega = ''
          }
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    const getAllValuesZonificacion = computed(() => {
      return costosZonificacion.value.map(res => {
        // console.log('Ingreso: ', res.ingreso)
        const utilOperacional = res.ingreso - (res.costo + res.gastoTotal)
        // console.log('utilOperacional: ', utilOperacional)
        const percentUtilOperacional = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((utilOperacional / res.ingreso) * 100).toFixed(0) + '%' : '0%'
        const percentCostoIngreso = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((res.costo / res.ingreso) * 100).toFixed(0) + '%' : '0%'
        const percentMargen = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((1 - (res.costo / res.ingreso)) * 100).toFixed(0) + '%' : '0%'
        return {
          ...res,
          utilOperacional: helper.formatCurrency(utilOperacional, 0),
          percentUtilOperacional,
          percentCostoIngreso,
          percentMargen
        }
      })
    })

    const getAllValuesBodega = computed(() => {
      return costosBodega.value.map(res => {
        const utilOperacional = res.ingreso - (res.costo + res.gastoTotal)
        const percentUtilOperacional = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((utilOperacional / res.ingreso) * 100).toFixed(0) + '%' : '0%'
        const percentCostoIngreso = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((res.costo / res.ingreso) * 100).toFixed(0) + '%' : '0%'
        const percentMargen = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((1 - (res.costo / res.ingreso)) * 100).toFixed(0) + '%' : '0%'
        return {
          ...res,
          utilOperacional: helper.formatCurrency(utilOperacional, 0),
          percentUtilOperacional,
          percentCostoIngreso,
          percentMargen
        }
      })
    })

    const getItemsZonificacion = computed(() => {
      return getAllValuesZonificacion.value.filter(a => {
        return a.municuser.toLowerCase().includes(searchNivelZonificacion.value.toLowerCase())
      })
    })

    const getItemsBodega = computed(() => {
      return getAllValuesBodega.value.filter(a => {
        return a.bodega.toLowerCase().includes(searchNivelBodega.value.toLowerCase())
      })
    })

    /**/
    const totalIngresosZonificacion = computed(() => {
      let total = 0
      for (const pres of getItemsZonificacion.value) {
        total += pres.ingreso ? pres.ingreso : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalCostosZonificacion = computed(() => {
      let total = 0
      for (const pres of getItemsZonificacion.value) {
        total += pres.costo ? pres.costo : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGastosZonificacion = computed(() => {
      let total = 0
      for (const pres of getItemsZonificacion.value) {
        total += pres.gastoTotal ? pres.gastoTotal : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /**/
    const totalIngresosBodega = computed(() => {
      let total = 0
      for (const pres of getItemsBodega.value) {
        total += pres.ingreso ? pres.ingreso : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalCostosBodega = computed(() => {
      let total = 0
      for (const pres of getItemsBodega.value) {
        total += pres.costo ? pres.costo : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGastosBodega = computed(() => {
      let total = 0
      for (const pres of getItemsBodega.value) {
        total += pres.gastoTotal ? pres.gastoTotal : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /**/
    const totalGastoGeneral = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.GastoGeneral ? pres.GastoGeneral : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGasto = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.GastoFiltrado ? pres.GastoFiltrado : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGastoPercent = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.percent ? pres.percent : 0
      }

      return `${Math.round(total)}%`
    })
    const totalGastoAsignado = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.gastoAsig ? pres.gastoAsig : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGastoDistribuido = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.gastoDist ? pres.gastoDist : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    const totalIngresoCliente = computed(() => {
      let total = 0
      for (const pres of getIngresosCostosCliente.value) {
        total += pres.ingreso ? pres.ingreso : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalCostoCliente = computed(() => {
      let total = 0
      for (const pres of getIngresosCostosCliente.value) {
        total += pres.costo ? pres.costo : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalPercentCliente = computed(() => {
      let total = 0
      for (const pres of getIngresosCostosCliente.value) {
        total += pres.percent ? pres.percent : 0
      }

      return `${Math.round(total)}%`
    })
    const totalpercentRentabilidadCliente = computed(() => {
      let total = 0
      for (const pres of getIngresosCostosCliente.value) {
        total += pres.percentRentabilidad ? pres.percentRentabilidad : 0
      }

      return `${Math.round(total)}%`
    })
    const totalpercentPesoIngresoCliente = computed(() => {
      let total = 0
      for (const pres of getIngresosCostosCliente.value) {
        total += pres.percentPesoIngreso ? pres.percentPesoIngreso : 0
      }

      return `${Math.round(total)}%`
    })
    const totalpercentPesoCostoCliente = computed(() => {
      let total = 0
      for (const pres of getIngresosCostosCliente.value) {
        total += pres.percentPesoCosto ? pres.percentPesoCosto : 0
      }

      return `${Math.round(total)}%`
    })

    const totalUtilidadOperacionalGeneral = computed(() => {
      return costos.value[0].ingresoGeneral - costos.value[1].costoGeneral - costos.value[4].gastoTotalGeneral
    })
    const percentUtilidadOperacionalGeneral = computed(() => {
      return (((costos.value[0].ingresoGeneral - costos.value[1].costoGeneral - costos.value[4].gastoTotalGeneral) / costos.value[0].ingresoGeneral) * 100).toFixed(0) + '%'
    })
    const totalUtilidadOperacionalFiltrado = computed(() => {
      return costos.value[0].ingreso - costos.value[1].costo - costos.value[4].gastoTotal
    })
    const percentUtilidadOperacionalFiltrado = computed(() => {
      return (((costos.value[0].ingreso - costos.value[1].costo - costos.value[4].gastoTotal) / costos.value[0].ingreso) * 100).toFixed(0) + '%'
    })

    const totalImpuestoRentaGeneral = computed(() => {
      return (costos.value[0].ingresoGeneral - costos.value[1].costoGeneral - costos.value[4].gastoTotalGeneral) * 0.31
    })
    const totalImpuestoRentaFiltrado = computed(() => {
      return (costos.value[0].ingreso - costos.value[1].costo - costos.value[4].gastoTotal) * 0.31
    })

    const totalUtilidadGeneral = computed(() => {
      return (costos.value[0].ingresoGeneral - costos.value[1].costoGeneral - costos.value[4].gastoTotalGeneral) - (costos.value[0].ingresoGeneral - costos.value[1].costoGeneral - costos.value[4].gastoTotalGeneral) * 0.31
    })
    const totalUtilidadFiltrado = computed(() => {
      const x1 = (costos.value[0].ingreso - costos.value[1].costo - costos.value[4].gastoTotal) * 0.31
      console.log('X1: ', x1)
      return (costos.value[0].ingreso - costos.value[1].costo - costos.value[4].gastoTotal) - (x1 < 0 ? 0 : x1)
    })

    const handleSearch = () => {
      if ([undefined, null].includes(getterFilters.value.month_start)) {
        messageWarning('Por favor debe seleccionar un mes de inicio para realizar la consulta.')
        return false
      }
      if ([undefined, null].includes(getterFilters.value.month_end)) {
        messageWarning('Por favor debe seleccionar un mes final para realizar la consulta.')
        return false
      }

      Promise.all([
        loadData(),
        loadDataGastos(),
        loadDataZonificacion(),
        loadDataBodega()
      ]).then(() => {
        getItemsUtilidad.value = [
          {
            concepto: 'Utilidad Operacional',
            totalGeneral: totalUtilidadOperacionalGeneral,
            percentGeneral: percentUtilidadOperacionalGeneral,
            totalFiltrado: totalUtilidadOperacionalFiltrado.value,
            percentFiltrado: percentUtilidadOperacionalFiltrado.value
          },
          {
            concepto: 'Impuesto de Renta',
            totalGeneral: totalImpuestoRentaGeneral,
            percentGeneral: '',
            totalFiltrado: totalImpuestoRentaFiltrado.value < 0 ? '$0' : totalImpuestoRentaFiltrado.value,
            percentFiltrado: ''
          },
          {
            concepto: 'Utilidad Final',
            totalGeneral: totalUtilidadGeneral,
            percentGeneral: '',
            totalFiltrado: totalUtilidadFiltrado.value,
            percentFiltrado: ''
          }
        ]
        cargando.value = false
        showHideIngCosCliente.value = false
      })
    }

    const handleClearFilters = () => {
      store.dispatch('filtersCardCostos/getClearAction', {
        month_start: null,
        month_end: null,
        year: new Date().getFullYear(),
        customer: null,
        bodega: null,
        municUsers: null,
        modalidad: null,
        dptoBodega: null,
        dptoUser: null
      }).then(() => {
        costos.value = []
        handleSearch()
      })
    }

    const filtersTableGastos = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      cmayor: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const filtersTableZonificacion = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      municuser: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const filtersTableBodega = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      bodega: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const onRowCollapseGastos = () => {
      gastoDetails.value = []
    }
    const onRowCollapseCostos = () => {
      costoDetails.value = []
    }

    const onRowExpandGastos = (event) => {
      // console.log(event)
    }
    const onRowExpandCostos = (event) => {
      // console.log(event)
    }

    return {
      searchNivelZonificacion,
      searchNivelBodega,
      getItemsZonificacion,
      getItemsBodega,
      getAllValuesBodega,
      getAllValuesZonificacion,
      costos,
      getItemsGastos,
      getItemsGastosGeneral,
      handleSearch,
      handleClearFilters,
      cargando,
      costosZonificacion,
      costosBodega,
      totalIngresosZonificacion,
      totalCostosZonificacion,
      totalGastosZonificacion,
      totalIngresosBodega,
      totalCostosBodega,
      totalGastosBodega,
      totalGastoGeneral,
      totalGasto,
      totalGastoPercent,
      totalGastoAsignado,
      totalGastoDistribuido,
      totalUtilidadOperacionalGeneral,
      percentUtilidadOperacionalGeneral,
      totalUtilidadOperacionalFiltrado,
      percentUtilidadOperacionalFiltrado,
      totalImpuestoRentaGeneral,
      totalImpuestoRentaFiltrado,
      totalUtilidadGeneral,
      totalUtilidadFiltrado,
      filtersTableGastos,
      filtersTableZonificacion,
      filtersTableBodega,
      getItemsUtilidad,
      getIngresos,
      getCostos,
      getUtilidadBruta,
      getGastos,
      getIngresosCostosCliente,
      totalIngresoCliente,
      totalCostoCliente,
      totalPercentCliente,
      totalpercentRentabilidadCliente,
      totalpercentPesoIngresoCliente,
      totalpercentPesoCostoCliente,
      showHideIngCosCliente,
      expandedRowsCostos,
      expandedRowsGastos,
      onRowCollapseGastos,
      onRowCollapseCostos,
      onRowExpandGastos,
      onRowExpandCostos
    }
  }
}
</script>

<style lang="scss">
.letra{
  font-size: 0.7em;
}
.custom-class{
  .p-component{
    font-size: 0.7rem;
  }
  .p-divider.p-divider-horizontal {
    margin: 0;
    padding: 0 1rem;
  }
  .p-divider.p-divider-horizontal.details {
    margin: 0.5rem 0;
  }
  .p-divider.p-divider-vertical {
    margin: 0;
    padding: 0 1rem;
  }
  .rowColorIngresos {
    background-color: #E2EFDA !important;
  }
  .rowColorCostos {
    background-color: #DDEBF7 !important;
  }
  .rowColorGastos {
    background-color: #FFDDDD !important;
  }
  .rowColorUtilBruta {
    background-color: #FFFFDD !important;
  }
}
</style>
